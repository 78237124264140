import { render, staticRenderFns } from "./_template.vue?vue&type=template&id=496b6c29&scoped=true&"
import script from "./_template.vue?vue&type=script&lang=js&"
export * from "./_template.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496b6c29",
  null
  
)

export default component.exports